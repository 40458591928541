import * as React from 'react';
import { PropsWithChildren } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardBody from 'reactstrap/lib/CardBody';
import MerchantImg from './MerchantImg';
import Card from 'reactstrap/lib/Card';
import Progress from 'reactstrap/lib/Progress';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../store';
import { bindActionCreators, Dispatch } from 'redux';
import selectors from '../store/selectors';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';

interface StateProps {
    successUrlCallback: string | null;
    merchantName: string;
}

type OwnProps = PropsWithChildren<{
    title: string;
}>;

type Props = StateProps & OwnProps;

interface State {
    progressEnabled: boolean;
    progress: number;
    redirecting: boolean;
}

class PayLinkSuccessCard extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            progressEnabled: false,
            progress: 0,
            redirecting: false,
        };
    }

    componentDidMount() {
        if (this.props.successUrlCallback) {
            this.setState({ progressEnabled: true });
            setInterval(() => {
                this.updateProgressBar();
            }, 100);
        }
    }

    updateProgressBar = () => {
        if (!this.props.successUrlCallback) return;
        if (!this.state.progressEnabled) return;

        // if (this.state.progress < 100) {
        //     this.setState({ progress: this.state.progress + 3 });
        // // } else {
        // //     setTimeout(() => {
        // //         if (this.props.successUrlCallback && !this.state.redirecting) {
        // //             this.setState({ redirecting: true });
        // //             window.location.href = this.props.successUrlCallback;
        // //         }
        // //     }, 500);
        // // }
    };

    componentWillUnmount() {
        this.setState({ progressEnabled: false });
    }

    render() {
        const { title, children, merchantName } = this.props;
        const { progress, progressEnabled } = this.state;

        return (
            <div className="h-100 d-flex align-items-center">
                <Card className="shadow-lg text-center mw-sm mx-auto m-3">
                    <CardBody className="p-md-5">
                        <MerchantImg className="mb-3" />
                        <h3 className="text-success" data-sh="message">
                            {title} <FontAwesomeIcon icon={faCheck} />
                        </h3>
                        {children}
                    </CardBody>
                    {progressEnabled ? (
                        <>
                            <small>
                                Taking you back to <strong>{merchantName}</strong>...
                            </small>
                            <Progress value={progress} striped animated className="progress-bar-bottom" />
                        </>
                    ) : null}
                </Card>
            </div>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state) => {
    return {
        successUrlCallback: selectors.payLink.getSuccessUrlCallback(state),
        merchantName: state.global.merchant.display_name,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PayLinkSuccessCard);
